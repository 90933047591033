import { HomebaseGraphs_homebase$key } from "__generated__/HomebaseGraphs_homebase.graphql";
import { ReactElement, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Text } from "theme-ui";

import AutoLayout from "../../../../components/01_Core/AutoLayout";
import { useActiveResponsiveValue } from "../../../../utils/responsiveUtils";
import { avgDeltaTimeseries } from "../../../../utils/timeseries";
import { formatNumeral } from "../../../../utils/utils";
import Timeseries from "../04_User_Profile/Timeseries";

function HomebaseGraphs(props: {
  homebase: HomebaseGraphs_homebase$key;
}): ReactElement {
  const homebase = useFragment(
    graphql`
      fragment HomebaseGraphs_homebase on HomebaseNode {
        instagramFollowers {
          timeseries {
            x
            y
          }
        }
        tiktokFollowers {
          timeseries {
            x
            y
          }
        }
        weeklyStreams {
          timeseries {
            x
            y
          }
        }
        spotifyFollowers {
          timeseries {
            x
            y
          }
        }
      }
    `,
    props.homebase
  );

  const graphs = {
    streaming: {
      label: "Streams Last Week",
      timeseriesProps: {
        statName: "Weekly streams",
        windowSummaryStatName: "Average weekly streams",
        windowSummaryFn: avgDeltaTimeseries
      },
      data: homebase.weeklyStreams.timeseries.map(ts => {
        return { x: new Date(ts.x), y: ts.y };
      })
    },
    spotifyFollowers: {
      label: "Spotify Followers",
      timeseriesProps: {
        statName: "Spotify Followers",
        windowSummaryStatName: "Average new Spotify followers per day",
        windowSummaryFn: avgDeltaTimeseries
      },
      data: homebase.spotifyFollowers.timeseries.map(ts => {
        return { x: new Date(ts.x), y: ts.y };
      })
    },
    instagram: {
      label: "Instagram Followers",
      timeseriesProps: {
        statName: "Instagram Followers",
        windowSummaryStatName: "Average new Instagram followers per day",
        windowSummaryFn: avgDeltaTimeseries
      },
      data: homebase.instagramFollowers.timeseries.map(ts => {
        return { x: new Date(ts.x), y: ts.y };
      })
    },
    tiktok: {
      label: "TikTok Followers",
      timeseriesProps: {
        statName: "TikTok Followers",
        windowSummaryStatName: "Average new TikTok followers per day",
        windowSummaryFn: avgDeltaTimeseries
      },
      data: homebase.tiktokFollowers.timeseries.map(ts => {
        return { x: new Date(ts.x), y: ts.y };
      })
    }
  };

  const [selectedGraph, setSelectedGraph] = useState<keyof typeof graphs>(
    Object.keys(graphs).find(
      key => graphs[key as keyof typeof graphs].data.length
    ) as keyof typeof graphs
  );

  const subheaderVariant = useActiveResponsiveValue([
    "bodySmall",
    "bodySmall",
    "bodyMedium",
    "bodyMedium"
  ]);

  return (
    <AutoLayout
      spacing={32}
      dependentProps={{ direction: "vertical" }}
      sx={{ width: "100%" }}
    >
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "horizontal" }}
        sx={{ width: "100%" }}
      >
        {Object.entries(graphs).map(([key, graph]) => {
          const canSelect = graph.data.length > 0;
          const graphLastDataPoint = graph.data[graph.data.length - 1];
          return (
            <Box
              key={key}
              sx={{
                width: "100%",
                paddingBottom: "16px",
                borderBottom: "2px solid",
                borderColor: selectedGraph === key ? "black100" : "midGray100",
                ":hover": canSelect && { borderColor: "black100" },
                cursor: canSelect ? "pointer" : "not-allowed"
              }}
              onClick={() =>
                canSelect && setSelectedGraph(key as keyof typeof graphs)
              }
            >
              <Text color={canSelect ? "black100" : "midGray100"}>
                <AutoLayout
                  spacing={8}
                  dependentProps={{ direction: "vertical" }}
                >
                  <Text variant={"h400"}>
                    {graphLastDataPoint
                      ? formatNumeral(graphLastDataPoint.y)
                      : "—"}
                  </Text>
                  <Text variant={subheaderVariant} color={"deepGray100"}>
                    {graph.label}
                  </Text>
                </AutoLayout>
              </Text>
            </Box>
          );
        })}
      </AutoLayout>
      <Timeseries
        timeseries={graphs[selectedGraph]?.data || []}
        windowGranularity={"day"}
        emptyText={"No data to show"}
        {...graphs[selectedGraph]?.timeseriesProps}
      />
    </AutoLayout>
  );
}

export default HomebaseGraphs;
