import { ReactElement, ReactNode, SyntheticEvent } from "react";
import { ResponsiveValue, SpaceProps } from "styled-system";
import { Flex, Text, Theme } from "theme-ui";

import { rvMap, useFindActiveRV } from "../../../utils/responsiveUtils";
import { noop } from "../../../utils/utils";
import { ButtonSize, IButtonIconProps } from "./Button";

export type TextButtonSize = "small" | "medium" | "large";
type TextButtonType =
  | "primary"
  | "secondary"
  | "contrastPrimary"
  | "destructive"
  | "success";

export interface ITextButtonProps extends SpaceProps {
  children: string | string[] | ReactElement;
  size: ResponsiveValue<TextButtonSize>;
  type: TextButtonType;
  disabled?: boolean;
  iconProps?: IButtonIconProps;
  onClick?: (e: SyntheticEvent) => void;
}

export function getColor(
  type: TextButtonType,
  disabled: boolean | undefined,
  theme: Theme
): Theme["colors"]["deepGray70"] {
  if (disabled) {
    return theme.colors.deepGray70;
  }

  switch (type) {
    case "primary":
      return theme.colors.primary100;
    case "secondary":
      return theme.colors.black100;
    case "contrastPrimary":
      return theme.colors.white100;
    case "destructive":
      return theme.colors.alert50;
    case "success":
      return theme.colors.success50;
  }
}

/**
 * figma: https://www.figma.com/file/PRosRrTMff449vklfDTcuv/01-Core?node-id=1278%3A23034
 */
function TextButton(props: ITextButtonProps): ReactElement {
  const textVariant = useFindActiveRV(props.size, getTextVariant);

  function getHoverColor(
    type: TextButtonType,
    disabled: boolean | undefined,
    theme: Theme
  ) {
    if (disabled) {
      return theme.colors.deepGray70;
    }

    if (type === "primary") {
      return theme.colors.primary85;
    } else {
      return getColor(type, disabled, theme);
    }
  }

  function getTextVariant(size: TextButtonSize) {
    switch (size) {
      case "small":
        return "bodySmall";
      case "medium":
        return "bodyMedium";
      case "large":
        return "bodyLarge";
    }
  }

  function getUnderlineColor(
    type: TextButtonType,
    disabled: boolean | undefined,
    theme: Theme
  ) {
    if (disabled) {
      return "unset";
    }

    switch (type) {
      case "primary":
        return theme.colors.midGray70;
      default:
        return getColor(type, disabled, theme);
    }
  }

  const getIcon = (
    iconNode: ReactNode,
    size: ResponsiveValue<ButtonSize>,
    placement: "left" | "right",
    type: TextButtonType,
    disabled: boolean | null
  ) => {
    return (
      <Flex
        mr={placement === "left" ? "4px" : null}
        ml={placement === "right" ? "4px" : null}
        sx={{
          color: theme => getColor(type, disabled, theme),
          size: rvMap(size, getIconSize),
          flexShrink: 0,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {iconNode}
      </Flex>
    );
  };

  const icon = props.iconProps
    ? getIcon(
        props.iconProps.icon,
        props.size,
        props.iconProps.placement,
        props.type,
        props.disabled
      )
    : null;

  const onClick = props.onClick ?? noop;

  return (
    <Flex
      onClick={props.disabled ? noop : onClick}
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: props.disabled ? "none" : "1px solid",
        borderColor: "transparent",
        transition: "border-color 0.2s",
        ":hover": {
          cursor: props.disabled ? "not-allowed" : "pointer",
          color: theme => getHoverColor(props.type, props.disabled, theme),
          borderColor: theme =>
            getUnderlineColor(props.type, props.disabled, theme)
        }
      }}
    >
      {icon != null && props.iconProps.placement === "left" ? icon : null}
      <Text
        variant={textVariant}
        sx={{
          color: theme => getColor(props.type, props.disabled, theme)
        }}
      >
        <strong>{props.children}</strong>
      </Text>
      {icon != null && props.iconProps.placement === "right" ? icon : null}
    </Flex>
  );
}

export default TextButton;

const getIconSize = (size: ButtonSize) => {
  switch (size) {
    case "small":
      return "16px";
    case "medium":
      return "20px";
    case "large":
      return "24px";
  }
};
