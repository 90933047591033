import "react-popper-tooltip/dist/styles.css";

import { ReactElement, ReactNode } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { ResponsiveValue } from "styled-system";
import { Box, Text, ThemeUIStyleObject, useThemeUI } from "theme-ui";

import Ic24Help from "../../../../imgs/icons/ic24-help.svg";
import { useFindActiveRV } from "../../../utils/responsiveUtils";
import AutoLayout from "../AutoLayout";

interface ITooltipProps {
  children: ReactNode;
  content?: ReactNode;
  styles?: ThemeUIStyleObject;
  size: ResponsiveValue<"small" | "medium" | "large">;
}

export function Tooltip({
  children,
  content,
  styles,
  size
}: ITooltipProps): ReactElement {
  const tooltip = usePopperTooltip(
    { placement: "top-start" },
    {
      modifiers: [
        {
          // Prevent overflow of parent container
          name: "preventOverflow",
          options: {
            altAxis: true
            // Adding any of the below options appears to break overflow prevention in at least one axis
            // Possibly from: https://github.com/floating-ui/floating-ui/issues/673
            // mainAxis: false,
            // boundary: document.querySelector('div[class*="FixedWidthGrid"]')
            // padding: 16
          }
        },
        {
          name: "offset",
          options: {
            offset: [0, 8]
          }
        }
      ]
    }
  );

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible: tooltipVisible
  } = tooltip;

  const contentTextVariant = useFindActiveRV(size, size => {
    switch (size) {
      case "small":
        return "bodySmall";
      case "medium":
      case "large":
        return "bodyMedium";
    }
  });

  return (
    <>
      <Box
        ref={setTriggerRef}
        onClick={e => e.stopPropagation()} // Without this, tooltip doesn't show on mobile click
        sx={{ size: "inherit", ...styles }}
      >
        {children}
      </Box>
      {content && tooltipVisible && (
        <Box
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
            sx: { maxWidth: "500px" }
          })}
        >
          <Box {...getArrowProps({ className: "tooltip-arrow" })} />
          <Text padding={"8px"} variant={contentTextVariant} color={"black100"}>
            {content}
          </Text>
        </Box>
      )}
    </>
  );
}

export function TooltipIconRight(
  props: ITooltipProps & { styles?: ThemeUIStyleObject }
): ReactElement {
  const { theme } = useThemeUI();

  const spacing = useFindActiveRV(props.size, size => {
    switch (size) {
      case "small":
      case "medium":
        return 4;
      case "large":
        return 8;
    }
  });

  const iconWidth = useFindActiveRV(props.size, size => {
    switch (size) {
      case "small":
        return "16px";
      case "medium":
        return "20px";
      case "large":
        return "24px";
    }
  });

  return (
    <AutoLayout
      spacing={spacing}
      dependentProps={{ direction: "horizontal", alignment: "center" }}
      sx={props.styles}
    >
      {props.children}
      <Tooltip content={props.content} size={props.size}>
        <Ic24Help
          color={theme.colors.deepGray70 as string}
          cursor={"help"}
          viewBox={`0 0 24 24`}
          style={{
            width: iconWidth,
            verticalAlign: "middle"
          }}
        />
      </Tooltip>
    </AutoLayout>
  );
}
