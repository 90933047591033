import { ReactElement, ReactNode } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Text } from "theme-ui";

import { FixedGrid } from "../../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../../components/01_Core/Grids/GridItem";
import ColumnHeader, {
  ColumnHeaderSortDir,
  ColumnHeaderState
} from "../../../../../components/01_Core/Tables/ColumnHeader";
import { getPlatformIcons } from "../../../../../utils/getPlatformIcons";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import {
  FeaturedDealSort,
  FeaturedDealSortType
} from "../../03_Discover/FeaturedDealDirectory";

type RowHeaderDealSize = "small" | "medium" | "large";

interface IRowHeaderDealProps {
  size: ResponsiveValue<RowHeaderDealSize>;
  sort: FeaturedDealSort;
  setSort: (sort: FeaturedDealSort) => void;
  showWatchedOnly: boolean;
  toggleShowWatchedOnly: () => void;
}

export function SocialIconLabel(props: {
  platform: Parameters<typeof getPlatformIcons>[0];
  size?: number | string;
  children?: ReactNode;
}): ReactElement {
  const { platform } = props;
  const [, [Icon]] = getPlatformIcons(platform);
  return (
    <IconLabel Icon={Icon} size={props.size}>
      {props.children}
    </IconLabel>
  );
}

export function IconLabel(props: {
  Icon: SvgrComponent;
  size?: number | string;
  spacing?: number | string;
  iconSide?: "left" | "right";
  children?: ReactNode;
}): ReactElement {
  const { Icon } = props;
  const size = props.size ?? "1.1em";
  const iconSide = props.iconSide ?? "left";

  const icon = (
    <Flex sx={{ height: "100%", alignItems: "center" }}>
      <Icon style={{ flexShrink: 0, width: size, height: size }} />
    </Flex>
  );
  const spacer = <Text sx={{ marginLeft: props.spacing ?? "0.55ch" }} />;
  const children =
    iconSide === "left"
      ? [icon, spacer, props.children]
      : [props.children, spacer, icon];
  return (
    <Text sx={{ display: "inline-flex" }}>
      {children.map((child, i) => (
        <Text key={i}>{child}</Text>
      ))}
    </Text>
  );
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2619%3A19811
 */
function RowHeaderDeal(props: IRowHeaderDealProps): ReactElement {
  const activeSize = useActiveResponsiveValue(props.size);
  const gridTemplateAreas = (() => {
    switch (activeSize) {
      case "small":
        return `"ar ar ar fa"`;
      case "medium":
        return `"ar ar ar tp tf ml ml fa"`;
      case "large":
        return `"ar ar ar ar tp tp tf tf ml ml da fa"`;
    }
  })();

  const getColHeaderSortDir = (
    sortType: FeaturedDealSortType
  ): ColumnHeaderSortDir => {
    return props.sort.type === sortType ? props.sort.dir : "desc";
  };

  const getColHeaderState = (
    sortType: FeaturedDealSortType
  ): ColumnHeaderState => {
    return props.sort.type === sortType ? "active" : "default";
  };

  const getOnClickSort = (sortType: FeaturedDealSortType) => () => {
    if (props.sort.type !== sortType) {
      props.setSort({
        type: sortType,
        dir: "desc"
      });
    } else {
      props.setSort({
        type: sortType,
        dir: props.sort.dir === "asc" ? "desc" : "asc"
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "white100",
        border: "1px solid",
        borderColor: "midGray70",
        width: "100%"
      }}
    >
      <FixedGrid sx={{ gridTemplateAreas }}>
        <GridItem gridArea={"ar"}>
          <ColumnHeader
            align={"left"}
            size={"small"}
            styles={{ paddingLeft: activeSize === "small" ? "0" : "16px" }}
            state={"default"}
          >
            Artist and Releases
          </ColumnHeader>
        </GridItem>
        {activeSize !== "small" && (
          <GridItem gridArea={"tp"}>
            <ColumnHeader
              sortDir={getColHeaderSortDir("tiktokPosts")}
              state={getColHeaderState("tiktokPosts")}
              onClick={getOnClickSort("tiktokPosts")}
              align={activeSize === "large" ? "left" : "right"}
              size={"small"}
            >
              <SocialIconLabel platform="TIKTOK">UGCs</SocialIconLabel>
            </ColumnHeader>
          </GridItem>
        )}
        {activeSize !== "small" && (
          <GridItem gridArea={"tf"}>
            <ColumnHeader
              sortDir={getColHeaderSortDir("tiktokFollowers")}
              state={getColHeaderState("tiktokFollowers")}
              onClick={getOnClickSort("tiktokFollowers")}
              align={activeSize === "large" ? "left" : "right"}
              size={"small"}
            >
              <SocialIconLabel platform="TIKTOK">Followers</SocialIconLabel>
            </ColumnHeader>
          </GridItem>
        )}
        {activeSize !== "small" && (
          <GridItem gridArea={"ml"}>
            <ColumnHeader
              sortDir={getColHeaderSortDir("spotifyMonthlyListeners")}
              state={getColHeaderState("spotifyMonthlyListeners")}
              onClick={getOnClickSort("spotifyMonthlyListeners")}
              align={activeSize === "large" ? "left" : "right"}
              size={"small"}
              styles={{ textWrap: "nowrap" }}
            >
              <SocialIconLabel platform="SPOTIFY">
                Monthly Listeners
              </SocialIconLabel>
            </ColumnHeader>
          </GridItem>
        )}
        {activeSize === "large" && (
          <GridItem gridArea={"da"}>
            <ColumnHeader
              sortDir={getColHeaderSortDir("date")}
              state={getColHeaderState("date")}
              onClick={getOnClickSort("date")}
              align={"right"}
              size={"small"}
            >
              Age
            </ColumnHeader>
          </GridItem>
        )}
      </FixedGrid>
    </Box>
  );
}

export default RowHeaderDeal;
