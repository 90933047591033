import { HomebaseQuery as HomebaseQueryType } from "__generated__/HomebaseQuery.graphql";
import { ReactElement } from "react";
import { graphql, PreloadedQuery, usePreloadedQuery } from "react-relay";
import { Link as ExternalLink, Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { FixedGrid } from "../../../../components/01_Core/Grids/Grid";
import { GridItem } from "../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../components/01_Core/Miscelleneous/Divider";
import { ExternalRoutes } from "../../Routing/ExternalRoutes";
import BlockLowEmphasis from "../03_UI_Kit/Marketing/BlockLowEmphasis";
import HomebaseFeedback from "./HomebaseFeedback";
import HomebaseGraphs from "./HomebaseGraphs";
import HomebaseGuidance from "./HomebaseGuidance";
import HomebaseHeader from "./HomebaseHeader";
import HomebaseInsights from "./HomebaseInsights";
import HomebaseTable from "./HomebaseTable";

function Homebase(props: {
  queryRef: PreloadedQuery<HomebaseQueryType>;
}): ReactElement {
  const homebase = usePreloadedQuery<HomebaseQueryType>(
    HomebaseQuery,
    props.queryRef
  ).homebase;

  return (
    <GridItem
      gridColumn={"1 / -1"}
      bg={"white100"}
      sx={{ flexDirection: "column", alignItems: "center", width: "100%" }}
    >
      <FixedGrid>
        <GridItem gridColumn={"1 / -1"}>
          <AutoLayout
            spacing={64}
            dependentProps={{ direction: "vertical" }}
            resizingX={FillContainer()}
            my={48}
          >
            <BlockLowEmphasis
              alert={true}
              size={"large"}
              sx={{ margin: "0 auto", maxWidth: "500px" }}
            >
              <AutoLayout
                spacing={24}
                dependentProps={{ direction: "vertical" }}
              >
                <Text variant={"h400"}>This feature is in beta</Text>
                <Text variant={"bodyMedium"}>
                  {
                    "As we're constantly iterating on and improving this feature, you may notice frequent changes or some occasional issues. We would appreciate if you would give us feedback by "
                  }
                  <ExternalLink
                    href={ExternalRoutes.i4aFeedback}
                    sx={{ textDecoration: "underline" }}
                  >
                    filling out this form.
                  </ExternalLink>
                </Text>
              </AutoLayout>
            </BlockLowEmphasis>
            <HomebaseHeader homebase={homebase} />
            <Divider saturation={"light"} />
            <HomebaseGuidance homebase={homebase} />
            <Divider saturation={"light"} />
            <HomebaseInsights homebase={homebase} />
            <Divider saturation={"light"} />
            <HomebaseGraphs homebase={homebase} />
            <HomebaseTable homebase={homebase} />
            <HomebaseFeedback size={["small", "small", "medium", "large"]} />
          </AutoLayout>
        </GridItem>
      </FixedGrid>
    </GridItem>
  );
}

export const HomebaseQuery = graphql`
  query HomebaseQuery {
    homebase {
      ...HomebaseHeader_homebase
      ...HomebaseGraphs_homebase
      ...HomebaseTable_homebase
      ...HomebaseInsights_homebase
      ...HomebaseGuidance_homebase
    }
  }
`;

export default Homebase;
