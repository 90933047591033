import {
  HomebaseTable_homebase$data,
  HomebaseTable_homebase$key
} from "__generated__/HomebaseTable_homebase.graphql";
import dayjs from "dayjs";
import numeral from "numeral";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { Box, Flex } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import { useBreakpoint } from "../../../../utils/useBreakpoints";

function HomebaseTable(props: {
  homebase: HomebaseTable_homebase$key;
}): ReactElement {
  const homebase = useFragment(
    graphql`
      fragment HomebaseTable_homebase on HomebaseNode {
        instagramFollowers {
          timeseries {
            x
            y
          }
        }
        tiktokFollowers {
          timeseries {
            x
            y
          }
        }
        weeklyStreams {
          timeseries {
            x
            y
          }
        }
        spotifyFollowers {
          timeseries {
            x
            y
          }
        }
      }
    `,
    props.homebase
  );

  const breakpointMap = useBreakpoint();

  return (
    <AutoLayout
      spacing={0}
      resizingX={FillContainer()}
      dependentProps={{ direction: "vertical" }}
    >
      <Flex
        sx={{
          width: "100%",
          "> div": {
            padding: "8px",
            flex: "1",
            border: theme => `1px solid ${theme.colors.midGray70}`,
            borderCollapse: "collapse"
          }
        }}
      >
        <Box>
          <strong>Platform</strong>
        </Box>
        <Box>
          <strong>Last Week</strong>
        </Box>
        <Box>
          <strong>Last Month</strong>
        </Box>
        <Box>
          <strong>Last 3 Months</strong>
        </Box>
        {!breakpointMap.mobile && (
          <Box>
            <strong>Last 6 Months</strong>
          </Box>
        )}
      </Flex>
      <TableRow label={"Streams"} data={homebase.weeklyStreams.timeseries} />
      <TableRow
        label={"Spotify Followers"}
        data={homebase.spotifyFollowers.timeseries}
      />
      <TableRow
        label={"Instagram Followers"}
        data={homebase.instagramFollowers.timeseries}
      />
      <TableRow
        label={"TikTok Followers"}
        data={homebase.tiktokFollowers.timeseries}
      />
    </AutoLayout>
  );
}

interface ITableRowProps {
  label: string;
  data: HomebaseTable_homebase$data["tiktokFollowers"]["timeseries"];
}

function TableRow(props: ITableRowProps): ReactElement {
  const breakpointMap = useBreakpoint();

  const week = lastWeekData(props.data);
  const month = lastMonthData(props.data);
  const threeMonths = lastThreeMonthsData(props.data);
  const sixMonths = lastSixMonthsData(props.data);

  const formatCellDataString = (change: number, percent: number) => {
    const formattedChange = numeral(change).format("0[.]0a");
    const formattedPercent = numeral(percent * 100).format("+0a");

    return `${formattedChange} (${formattedPercent}%)`;
  };

  return (
    <Flex
      sx={{
        width: "100%",
        color: theme => theme.colors.deepGray100,
        "> div": {
          padding: "8px",
          flex: "1",
          border: theme => `1px solid ${theme.colors.midGray70}`,
          borderCollapse: "collapse"
        }
      }}
    >
      <Box sx={{ color: theme => theme.colors.black100 }}>{props.label}</Box>
      <Box>
        {week ? `${formatCellDataString(week.change, week.percent)}` : "-"}
      </Box>
      <Box>
        {month ? `${formatCellDataString(month.change, month.percent)}` : "-"}
      </Box>
      <Box>
        {threeMonths
          ? `${formatCellDataString(threeMonths.change, threeMonths.percent)}`
          : "-"}
      </Box>
      {!breakpointMap.mobile && (
        <Box>
          {sixMonths
            ? `${formatCellDataString(sixMonths.change, sixMonths.percent)}`
            : "-"}
        </Box>
      )}
    </Flex>
  );
}

function lastWeekData(
  data: ITableRowProps["data"]
): { change: number; percent: number } | null {
  const lastWeek = data.filter(d =>
    dayjs().subtract(1, "week").isBefore(dayjs(d.x))
  );

  if (lastWeek.length === 0) {
    return null;
  }

  const lastWeekChange =
    lastWeek.length > 1 ? lastWeek[lastWeek.length - 1].y - lastWeek[0].y : 0;
  const lastWeekPercent =
    lastWeek.length > 1 && lastWeek[0].y > 0
      ? lastWeekChange / lastWeek[0].y
      : 0;

  return { change: lastWeekChange, percent: lastWeekPercent };
}

function lastMonthData(
  data: ITableRowProps["data"]
): { change: number; percent: number } | null {
  const lastMonth = data.filter(d =>
    dayjs().subtract(1, "month").isBefore(dayjs(d.x))
  );

  if (lastMonth.length === 0) {
    return null;
  }

  const lastMonthChange =
    lastMonth.length > 1
      ? lastMonth[lastMonth.length - 1].y - lastMonth[0].y
      : 0;
  const lastMonthPercent =
    lastMonth.length > 1 && lastMonth[0].y > 0
      ? lastMonthChange / lastMonth[0].y
      : 0;

  return { change: lastMonthChange, percent: lastMonthPercent };
}

function lastThreeMonthsData(
  data: ITableRowProps["data"]
): { change: number; percent: number } | null {
  const lastThreeMonths = data.filter(d =>
    dayjs().subtract(3, "month").isBefore(dayjs(d.x))
  );

  if (lastThreeMonths.length === 0) {
    return null;
  }

  const lastThreeMonthsChange =
    lastThreeMonths.length > 1
      ? lastThreeMonths[lastThreeMonths.length - 1].y - lastThreeMonths[0].y
      : 0;
  const lastThreeMonthsPercent =
    lastThreeMonths.length > 1 && lastThreeMonths[0].y > 0
      ? lastThreeMonthsChange / lastThreeMonths[0].y
      : 0;

  return { change: lastThreeMonthsChange, percent: lastThreeMonthsPercent };
}

function lastSixMonthsData(
  data: ITableRowProps["data"]
): { change: number; percent: number } | null {
  const lastSixMonths = data.filter(d =>
    dayjs().subtract(6, "month").isBefore(dayjs(d.x))
  );

  if (lastSixMonths.length === 0) {
    return null;
  }

  const lastSixMonthsChange =
    lastSixMonths.length > 1
      ? lastSixMonths[lastSixMonths.length - 1].y - lastSixMonths[0].y
      : 0;
  const lastSixMonthsPercent =
    lastSixMonths.length > 1 && lastSixMonths[0].y > 0
      ? lastSixMonthsChange / lastSixMonths[0].y
      : 0;

  return { change: lastSixMonthsChange, percent: lastSixMonthsPercent };
}

export default HomebaseTable;
