import {
  ActionItemPlatformPlatform,
  HomebaseGuidance_actionItem$key,
  PlatformFormattedValue
} from "__generated__/HomebaseGuidance_actionItem.graphql";
import { HomebaseGuidance_homebase$key } from "__generated__/HomebaseGuidance_homebase.graphql";
import _ from "lodash";
import { ReactElement, useState } from "react";
import { graphql, useFragment } from "react-relay";
import ReactSelect from "react-select";
import { Box, Checkbox, Grid, Label, Text } from "theme-ui";

import Ic24Filter from "../../../../../imgs/icons/ic24-filter.svg";
import Ic24Instagram from "../../../../../imgs/icons/ic24-instagram.svg";
import Ic24TikTok from "../../../../../imgs/icons/ic24-social-tiktok.svg";
import Ic24Voice from "../../../../../imgs/icons/ic24-voice.svg";
import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import SubtleBadge from "../../../../components/01_Core/Badges_and_Tags/SubtleBadge";
import { getBaseStyles } from "../../../../components/01_Core/Forms/Inputs/SelectInput";
import { IconBox } from "../../../../components/01_Core/Foundations/Icons";
import useToggleCheckActionItem from "../../../../mutations/useToggleCheckActionItem";
import { IconLabel } from "../03_UI_Kit/Discover/RowHeaderDeal";

type AvailablePlatformsValue = "ALL" | ActionItemPlatformPlatform;
type AvailablePlatformsLabel = "All Platforms" | PlatformFormattedValue;

interface PlatformOption {
  value: AvailablePlatformsValue;
  label: AvailablePlatformsLabel;
}

const platformsOptions: PlatformOption[] = [
  { value: "ALL", label: "All Platforms" },
  ..._.map(GLOBALS.ACTION_ITEM_PLATFORMS, (value, key) => {
    return {
      value: key as ActionItemPlatformPlatform,
      label: value.displayName as PlatformFormattedValue
    };
  })
];

function HomebaseGuidance(props: {
  homebase: HomebaseGuidance_homebase$key;
}): ReactElement {
  const homebase = useFragment(
    graphql`
      fragment HomebaseGuidance_homebase on HomebaseNode {
        actionItems {
          id
          checked
          platforms {
            edges {
              node {
                platform
              }
            }
          }
          ...HomebaseGuidance_actionItem
        }
      }
    `,
    props.homebase
  );

  const [showCheckedActionItems, setShowCheckedActionItems] = useState(false);
  const toggleShowCheckedActionItems = () =>
    setShowCheckedActionItems(!showCheckedActionItems);

  const [selectedPlatform, setSelectedPlatform] =
    useState<AvailablePlatformsValue>("ALL");

  const visibleActionItems = homebase.actionItems.filter(
    actionItem =>
      actionItem.checked === showCheckedActionItems &&
      (selectedPlatform === "ALL" ||
        actionItem.platforms.edges.some(
          ({ node }) => node.platform === selectedPlatform
        ))
  );

  return (
    <AutoLayout
      spacing={32}
      dependentProps={{ direction: "vertical" }}
      resizingX={FillContainer()}
    >
      <Text variant={"h500"}>Guidance</Text>
      <GuidanceFilters
        setSelectedPlatform={setSelectedPlatform}
        showCheckedActionItems={showCheckedActionItems}
        toggleShowCheckedActionItems={toggleShowCheckedActionItems}
      />
      {visibleActionItems.length === 0 ? (
        <Text variant={"bodyLarge"}>
          <em>No action items</em>
        </Text>
      ) : (
        <Grid
          columns={[1, 1, 2, 2]}
          gap={32}
          sx={{
            maxHeight: ["500px", "500px", "750px", "750px"],
            overflowY: "auto"
          }}
        >
          {visibleActionItems.map(actionItem => (
            <ActionItem key={actionItem.id} actionItem={actionItem} />
          ))}
        </Grid>
      )}
    </AutoLayout>
  );
}

function GuidanceFilters(props: {
  setSelectedPlatform: (platform: AvailablePlatformsValue) => void;
  showCheckedActionItems: boolean;
  toggleShowCheckedActionItems: () => void;
}): ReactElement {
  return (
    <AutoLayout
      spacing={[16, 16, 0, 0]}
      dependentProps={[
        {
          direction: "vertical",
          alignment: "center"
        },
        {
          direction: "vertical",
          alignment: "center"
        },
        {
          direction: "horizontal",
          alignment: "center",
          distribution: "spaceBetween"
        },
        {
          direction: "horizontal",
          alignment: "center",
          distribution: "spaceBetween"
        }
      ]}
      resizingX={FillContainer()}
      sx={{
        padding: "16px",
        borderRadius: "4px",
        backgroundColor: theme => theme.colors.lightGray95
      }}
    >
      <Text variant={"bodyMedium"}>
        <IconLabel Icon={Ic24Filter}>
          <strong>Filters</strong>
        </IconLabel>
      </Text>
      <AutoLayout
        spacing={[16, 16, 32, 32]}
        dependentProps={[
          { direction: "vertical", alignment: "center" },
          { direction: "vertical", alignment: "center" },
          { direction: "horizontal", alignment: "center" },
          { direction: "horizontal", alignment: "center" }
        ]}
      >
        <Box sx={{ width: "250px" }}>
          <ReactSelect<PlatformOption>
            onChange={platform => props.setSelectedPlatform(platform.value)}
            styles={getBaseStyles("medium", "normal", false)}
            formatOptionLabel={({ value, label }) => {
              const icon = getPlatformIcon(value);
              return (
                <AutoLayout
                  spacing={8}
                  dependentProps={{
                    direction: "horizontal",
                    alignment: "center"
                  }}
                >
                  {icon && <IconBox icon={icon} sx={{ size: 20 }} />}
                  {label}
                </AutoLayout>
              );
            }}
            defaultValue={platformsOptions[0]}
            options={platformsOptions}
          />
        </Box>
        <Label sx={{ width: "max-content", cursor: "pointer" }}>
          <AutoLayout
            spacing={4}
            dependentProps={{ direction: "horizontal", alignment: "center" }}
          >
            <Checkbox
              defaultChecked={props.showCheckedActionItems}
              onClick={props.toggleShowCheckedActionItems}
            />
            <Text variant={"bodyMedium"}>Show completed items</Text>
          </AutoLayout>
        </Label>
      </AutoLayout>
    </AutoLayout>
  );
}

function ActionItem(props: {
  actionItem: HomebaseGuidance_actionItem$key;
}): ReactElement {
  const actionItem = useFragment(
    graphql`
      fragment HomebaseGuidance_actionItem on ActionItemNode {
        id
        title
        description
        platforms {
          edges {
            node {
              id
              platform
              platformFormatted
            }
          }
        }
        checked
        ...useToggleCheckActionItem_actionItem
      }
    `,
    props.actionItem
  );

  const [toggleCheckActionItem] = useToggleCheckActionItem(actionItem);

  return (
    <AutoLayout
      spacing={0}
      dependentProps={{ direction: "vertical" }}
      resizingX={FillContainer()}
      resizingY={FillContainer()}
      sx={{
        borderRadius: "4px",
        border: theme => `1px solid ${theme.colors.midGray70}`,
        "&:hover": {
          border: theme => `1px solid ${theme.colors.midGray100}`
        }
      }}
    >
      <AutoLayout
        spacing={8}
        dependentProps={{ direction: "horizontal" }}
        resizingX={FillContainer()}
        sx={{
          padding: "16px",
          backgroundColor: theme => theme.colors.lightGray95,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px"
        }}
      >
        {actionItem.platforms.edges.map(({ node }) => (
          <SubtleBadge key={node.id} size={"small"} color={"stroked"}>
            <AutoLayout
              spacing={4}
              dependentProps={{
                direction: "horizontal",
                alignment: "center"
              }}
            >
              <IconBox
                icon={getPlatformIcon(node.platform)}
                sx={{ size: 16 }}
              />
              {node.platformFormatted}
            </AutoLayout>
          </SubtleBadge>
        ))}
      </AutoLayout>
      <AutoLayout
        spacing={24}
        dependentProps={{ direction: "vertical" }}
        resizingX={FillContainer()}
        sx={{
          padding: "16px",
          backgroundColor: theme => theme.colors.white100,
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px"
        }}
      >
        <AutoLayout
          spacing={12}
          dependentProps={{ direction: "horizontal", alignment: "topLeft" }}
        >
          <Label
            htmlFor={`checkbox-${actionItem.id}`}
            mt={"2px"}
            sx={{ width: "min-content", cursor: "pointer" }}
          >
            <Checkbox
              id={`checkbox-${actionItem.id}`}
              defaultChecked={actionItem.checked}
              onClick={toggleCheckActionItem}
            />
          </Label>
          <AutoLayout
            spacing={16}
            dependentProps={{ direction: "vertical" }}
            resizingX={FillContainer()}
          >
            <Label
              htmlFor={`checkbox-${actionItem.id}`}
              sx={{ cursor: "pointer" }}
            >
              <Text
                variant={"h300"}
                sx={{
                  textDecoration: actionItem.checked
                    ? "line-through"
                    : "initial"
                }}
              >
                {actionItem.title}
              </Text>
            </Label>
            <Text
              variant={"bodyMedium"}
              color={"deepGray100"}
              sx={{ whiteSpace: "pre", textWrap: "wrap" }}
            >
              {actionItem.description}
            </Text>
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
}

function getPlatformIcon(platform: AvailablePlatformsValue) {
  switch (platform) {
    case "INSTAGRAM":
      return Ic24Instagram;
    case "TIKTOK":
      return Ic24TikTok;
    case "STREAMING":
      return Ic24Voice;
    default:
      return null;
  }
}

export default HomebaseGuidance;
