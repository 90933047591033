import { useCanAccessMarketplace_authUser$key } from "__generated__/useCanAccessMarketplace_authUser.graphql";
import { graphql, useFragment } from "react-relay";

import { useAuth } from "./useAuth";

export default function useCanAccessMarketplace(): [boolean, string] {
  const authUser = useFragment<useCanAccessMarketplace_authUser$key>(
    graphql`
      fragment useCanAccessMarketplace_authUser on UserNode {
        canAccessMarketplace
      }
    `,
    useAuth().authUser
  );

  const canAccessMarketplace = authUser?.canAccessMarketplace ?? false;
  return [
    canAccessMarketplace,
    canAccessMarketplace ? null : "Join the marketplace to access this feature!"
  ];
}
