import { ReactElement } from "react";
import { ResponsiveValue } from "styled-system";
import { Box, Flex, Grid, Text } from "theme-ui";

import AutoLayout from "../../../../../components/01_Core/AutoLayout";
import {
  GridItem,
  GridItemProps
} from "../../../../../components/01_Core/Grids/GridItem";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { Tooltip } from "../../../../../components/01_Core/Tooltips/Tooltip";
import AuthUserAvatarName from "../../../../../components/01_Core/Users/AuthUserAvatarName";
import { Artist } from "../../../../../types/users";
import MarketplaceAccessGate from "../../../../../utils/MarketplaceAccessGate";
import { rvMap } from "../../../../../utils/responsiveUtils";
import { useAuth } from "../../../hooks/useAuth";
import usePartnerCanViewDeals from "../../../hooks/usePartnerCanViewDeals";
import {
  artistReferralsSubpage,
  artistRosterSubpage,
  bankSettingsSubpage,
  basicInfoSubpage,
  companyInfoSubpage,
  companySocialLinksSubpage,
  companyTeamSubpage,
  dealsSubpage,
  emailSubscriptionsSubpage,
  manageReleasesSubpage,
  managerInfoSubpage,
  partnerReferralsSubpage,
  passwordSubpage,
  socialLinksSubpage,
  Subpage,
  subscriptionSubpage,
  teamMembersSubpage
} from "../../08_Account_Management/AccountManagementPage";
import NavItem from "../../08_Account_Management/NavItem";

type AccountNavSize = "small" | "large";

interface IAccountNavProps {
  size: ResponsiveValue<AccountNavSize>;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1340%3A1168
 */
function AccountNav(props: IAccountNavProps): ReactElement {
  const { authUser, signOut } = useAuth();
  const [partnerCanViewDeals, partnerCannotViewDealsText] =
    usePartnerCanViewDeals();

  const userType = authUser.userType;

  const gridTemplateAreas =
    userType === Artist
      ? rvMap(props.size, getArtistNavAreas)
      : rvMap(props.size, getPartnerNavAreas);

  const avatarName = <AuthUserAvatarName size={"extraLarge"} />;

  return (
    <Box sx={{ width: "100%" }}>
      <Flex
        padding={"32px"}
        bg={"white100"}
        sx={{
          display: rvMap(props.size, getDisplayOuterAvatar),
          border: "1px solid",
          borderColor: "midGray70"
        }}
      >
        {avatarName}
      </Flex>
      <AutoLayout
        spacing={24}
        dependentProps={{
          direction: "vertical"
        }}
        p={rvMap(props.size, getPadding)}
        backgroundColor={rvMap(props.size, getBg)}
        sx={{
          border: rvMap(props.size, getBorder),
          borderColor: rvMap(props.size, getBorderColor),
          height: "fit-content",
          overflowX: "auto",
          width: "100%"
        }}
      >
        <Box sx={{ display: rvMap(props.size, getDisplayInnerAvatar) }}>
          {avatarName}
        </Box>
        <Grid
          gap={"24px"}
          sx={{
            gridTemplateColumns: rvMap(props.size, getGridTemplateColumns),
            gridRowGap: rvMap(props.size, getGridRowGap),
            gridTemplateAreas: gridTemplateAreas,
            width: "100%"
          }}
        >
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"ci"}
            label={"Company Info"}
            subpage={companyInfoSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"cs"}
            label={"Company Social Links"}
            subpage={companySocialLinksSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"ct"}
            label={"Company Team"}
            subpage={companyTeamSubpage}
          />
          <Tooltip content={partnerCannotViewDealsText} size="medium">
            <ConditionalGridNavItem
              {...{ gridTemplateAreas }}
              gridArea={"ar"}
              label={"Artist Roster"}
              subpage={artistRosterSubpage}
              disabled={!partnerCanViewDeals}
            />
          </Tooltip>
          <GridItem gridArea={"d1"} {...{ gridTemplateAreas }} pb={"12px"}>
            <Divider />
          </GridItem>
          <GridNavItem
            gridArea={"bi"}
            label={"Basic Info"}
            subpage={basicInfoSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"de"}
            label={"Deal Proposals"}
            subpage={dealsSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"mr"}
            label={"Releases"}
            subpage={manageReleasesSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"sl"}
            label={"Social Links"}
            subpage={socialLinksSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"mg"}
            label={"Manager"}
            subpage={managerInfoSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"tm"}
            label={"Partners"}
            subpage={teamMembersSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"bs"}
            label={"Bank Settings"}
            subpage={bankSettingsSubpage}
          />
          <GridItem gridArea={"d1pt5"} {...{ gridTemplateAreas }} py={"12px"}>
            <Divider />
          </GridItem>
          <MarketplaceAccessGate>
            <ConditionalGridNavItem
              {...{ gridTemplateAreas }}
              gridArea={"rp"}
              label={"Refer Other Investors"}
              subpage={partnerReferralsSubpage}
            />
          </MarketplaceAccessGate>
          <MarketplaceAccessGate>
            <ConditionalGridNavItem
              {...{ gridTemplateAreas }}
              gridArea={"ra"}
              label={"Refer Artists"}
              subpage={artistReferralsSubpage}
            />
          </MarketplaceAccessGate>
          <GridItem gridArea={"d2"} {...{ gridTemplateAreas }} py={"12px"}>
            <Divider />
          </GridItem>
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"em"}
            label={"Email Notifications"}
            subpage={emailSubscriptionsSubpage}
          />
          <ConditionalGridNavItem
            {...{ gridTemplateAreas }}
            gridArea={"sb"}
            label={"Subscription"}
            subpage={subscriptionSubpage}
          />
          <GridItem {...{ gridTemplateAreas }} gridArea={"pw"}>
            <NavItem label={"Password"} subpage={passwordSubpage} />
          </GridItem>
          <GridItem {...{ gridTemplateAreas }} gridArea={"lo"}>
            <Flex
              onClick={signOut}
              sx={{
                cursor: "pointer",
                alignItems: "center",
                height: "100%",
                paddingRight: rvMap(props.size, getLogoutPaddingRight)
              }}
            >
              <Text
                variant={"bodyMedium"}
                color={"deepGray100"}
                sx={{
                  whiteSpace: "nowrap",
                  ":hover": {
                    color: "black100"
                  }
                }}
              >
                Logout
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </AutoLayout>
    </Box>
  );
}

function ConditionalGridNavItem(
  props: {
    label: string;
    subpage: Subpage;
    disabled?: boolean;
  } & Omit<GridItemProps, "children">
): ReactElement {
  const { label, subpage, disabled, ...rest } = props;

  return (
    <GridItem {...rest}>
      <NavItem label={label} subpage={subpage} disabled={disabled} />
    </GridItem>
  );
}
function GridNavItem(
  props: {
    gridArea: string;
    label: string;
    subpage: Subpage;
  } & Omit<GridItemProps, "children">
): ReactElement {
  const { gridArea, label, subpage, ...rest } = props;

  return (
    <GridItem gridArea={gridArea} {...rest}>
      <NavItem label={label} subpage={subpage} />
    </GridItem>
  );
}

function getArtistNavAreas(size: AccountNavSize) {
  switch (size) {
    case "small":
      return `
        "bi de mr sl mg tm bs em pw lo"
        `;
    case "large":
      return `
        "d1"
        "bi"
        "de"
        "mr"
        "sl"
        "mg"
        "tm"
        "bs"
        "d2"
        "em"
        "pw"
        "lo"
        `;
  }
}

function getPartnerNavAreas(size: AccountNavSize) {
  switch (size) {
    case "small":
      return `
        "bi ci cs ct ar rp ra em pw lo"
  `;
    case "large":
      return `
        "d1"
        "bi"
        "ci"
        "cs"
        "ct"
        "ar"
        "d1pt5"
        "rp"
        "ra"
        "d2"
        "em"
        "pw"
        "lo"
  `;
  }
}

function getBg(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "unset";
    case "large":
      return "white100";
  }
}

function getBorder(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "none";
    case "large":
      return "1px solid";
  }
}

function getBorderColor(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "none";
    case "large":
      return "midGray70";
  }
}

function getGridRowGap(size: AccountNavSize) {
  switch (size) {
    case "small":
      return 0;
    case "large":
      return 12;
  }
}

function getGridTemplateColumns(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "repeat(9, min-content)";
    case "large":
      return "auto";
  }
}

function getPadding(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "22px 88px 22px 32px";
    case "large":
      return "32px";
  }
}

function getDisplayInnerAvatar(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "none";
    case "large":
      return "initial";
  }
}

function getDisplayOuterAvatar(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "flex";
    case "large":
      return "none";
  }
}

function getLogoutPaddingRight(size: AccountNavSize) {
  switch (size) {
    case "small":
      return "88px";
    case "large":
      return "unset";
  }
}

export default AccountNav;
