import { usePartnerCanViewDeals_authUser$key } from "__generated__/usePartnerCanViewDeals_authUser.graphql";
import { graphql, useFragment } from "react-relay";

import { useAuth } from "./useAuth";
import useCanAccessMarketplace from "./useCanAccessMarketplace";

export default function usePartnerCanViewDeals(): [boolean, string] {
  const [canAccessMarketplace, cannotAccessMarketplaceText] =
    useCanAccessMarketplace();

  const { authUser: authUserQuery } = useAuth();
  const authUser = useFragment<usePartnerCanViewDeals_authUser$key>(
    graphql`
      fragment usePartnerCanViewDeals_authUser on UserNode {
        partner {
          canViewPortfolio
          company {
            offers(isAccepted: true) {
              edges {
                node {
                  __typename
                }
              }
            }
          }
        }
      }
    `,
    authUserQuery
  );

  const couldHaveDeals =
    canAccessMarketplace || authUser.partner?.company.offers.edges.length > 0;
  if (couldHaveDeals) {
    if (authUser.partner?.canViewPortfolio) {
      return [true, null];
    } else {
      return [false, "You don't have access to this page."];
    }
  } else {
    return [false, cannotAccessMarketplaceText];
  }
}
